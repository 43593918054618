import { CloseButton } from "@components/buttons/close";
import { CopyButton } from "@components/buttons/copy";
import {
  SHAvatar,
  SHBox,
  SHChip,
  SHPopper,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { TooltipAllowSVG } from "@components/svgs";
import { ProductBDMUserDTO } from "@models/product/entities/product";
import { Link, useTheme, Zoom } from "@mui/material";
import shadows from "@mui/material/styles/shadows";
import { HtmlTooltip } from "@pages/platform-analysis/_id/steps/fee/analysis/components/sub-product-card";
import { hexToRGBA } from "@utils";
import { defaultTo, isEmpty } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import Truncate from "react-truncate";
import { useToggle } from "react-use";

export interface UserInformationCardProps extends SHStackProps {
  userInfo?: ProductBDMUserDTO;
  showUserType?: Boolean;
}

export const UserInformationCard = ({
  userInfo,
  showUserType = false,
  ...others
}: UserInformationCardProps) => {
  const MAX_NUM_OF_EMAIL_CHARACTERS_DISPLAY = 30;
  const MAX_NUM_OF_NAME_CHARACTERS_DISPLAY = 20;
  const MAX_NUM_OF_JOB_TITLE_CHARACTERS_DISPLAY = 30;

  const { palette } = useTheme();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLLabelElement | null>(null);

  const [isEmailTooltipOpening, openEmailTooltip] = useToggle(false);
  const [isNameTooltipOpening, openNameTooltip] = useToggle(false);
  const [isJobTitleTooltipOpening, openJobTitleTooltip] = useToggle(false);

  const regionDisplayName = useMemo(() => {
    return userInfo?.regions?.join(", ");
  }, [userInfo?.regions]);

  const handleOnShowMore = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!open) return;
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        labelRef.current &&
        !labelRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, labelRef, open]);

  const renderTooltipDisplay = (params: {
    isOpen: boolean;
    tooltipContent?: string;
    displayContent: JSX.Element;
    maxCharactersDisplay: number;
    openTooltipFunction: Function;
  }) => {
    const {
      isOpen,
      displayContent,
      tooltipContent,
      maxCharactersDisplay,
      openTooltipFunction,
    } = params;

    return (
      <HtmlTooltip
        open={isOpen}
        followCursor
        TransitionComponent={Zoom}
        placement="top-start"
        title={
          <SHStack
            sx={{
              bgcolor: hexToRGBA(palette.common.white, 0.9),
              border: "1px solid #E3E3E3",
              backdropFilter: "blur(5px)",
              borderRadius: "3px",
              boxShadow: shadows[1],
              position: "relative",
              ml: "-19px",
              p: 1,
            }}
          >
            <SHTypography variant="body1">{tooltipContent}</SHTypography>
            <SHBox
              sx={{
                position: "absolute",
                bottom: "-11.7px",
                left: 0,
              }}
            >
              <TooltipAllowSVG />
            </SHBox>
          </SHStack>
        }
        onClose={() => openTooltipFunction(false)}
        onOpen={() => {
          if (!tooltipContent || tooltipContent.length < maxCharactersDisplay)
            return;

          openTooltipFunction(true);
        }}
      >
        <SHStack
          sx={{
            lineBreak: "anywhere",
            WebkitLineBreak: "anywhere",
            overflow: "hidden",
            display: "-webkit-box",
            lineClamp: 1,
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          }}
        >
          {displayContent}
        </SHStack>
      </HtmlTooltip>
    );
  };

  return (
    <SHStack
      height={245}
      width={{ lg: "32.75%", md: "49.33%", sm: "49.3%" }}
      justifyContent="space-between"
      alignItems="stretch"
      sx={{
        padding: "10px",
        boxSizing: "border-box",
        backdropFilter: "blur(0.5px)",
        background: `${hexToRGBA(palette.common.white, 0.25)}`,
        border: `1px solid ${palette.secondary[100]}`,
      }}
      {...others}
    >
      <SHStack
        width={"100%"}
        flexDirection={"row"}
        alignItems={"flex-start"}
        sx={{
          gap: "10px",
        }}
        {...others}
      >
        <SHStack width="75%" gap={"5px"}>
          <SHStack>
            {renderTooltipDisplay({
              isOpen: isNameTooltipOpening,
              openTooltipFunction: openNameTooltip,
              tooltipContent: userInfo?.name,
              maxCharactersDisplay: MAX_NUM_OF_NAME_CHARACTERS_DISPLAY,
              displayContent: (
                <SHTypography
                  variant="h3"
                  sx={{
                    padding: 0,
                    fontSize: 18,
                  }}
                >
                  {userInfo?.name}
                </SHTypography>
              ),
            })}

            {showUserType ? (
              <SHTypography
                variant="h3"
                colorVariant="third"
                sx={{ padding: 0, fontSize: 18 }}
              >
                {userInfo?.userType}
              </SHTypography>
            ) : (
              <></>
            )}

            {renderTooltipDisplay({
              isOpen: isJobTitleTooltipOpening,
              openTooltipFunction: openJobTitleTooltip,
              tooltipContent: userInfo?.jobTitle,
              maxCharactersDisplay: MAX_NUM_OF_JOB_TITLE_CHARACTERS_DISPLAY,
              displayContent: (
                <SHTypography
                  variant="body1"
                  colorVariant="third"
                  sx={{
                    padding: 0,
                  }}
                >
                  {userInfo?.jobTitle}
                </SHTypography>
              ),
            })}
          </SHStack>
          <SHStack>
            <SHTypography
              variant="body1"
              colorVariant="third"
              sx={{
                padding: 0,
              }}
            >
              {userInfo?.publicPhoneNumber}
            </SHTypography>

            {userInfo?.publicEmail && (
              <SHStack direction="row" gap={1}>
                {renderTooltipDisplay({
                  isOpen: isEmailTooltipOpening,
                  openTooltipFunction: openEmailTooltip,
                  tooltipContent: userInfo?.publicEmail,
                  maxCharactersDisplay: MAX_NUM_OF_EMAIL_CHARACTERS_DISPLAY,
                  displayContent: (
                    <Link
                      variant="body1"
                      component="a"
                      underline="none"
                      sx={{
                        padding: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        cursor: "pointer",
                        color: palette.text.secondary,
                        maxWidth: 230,
                      }}
                      href={`mailto:${userInfo?.publicEmail}`}
                    >
                      {userInfo?.publicEmail}
                    </Link>
                  ),
                })}

                <CopyButton
                  tooltipTitle="Copy"
                  copyValue={userInfo?.publicEmail}
                  notifyContent="Email address copied to clipboard"
                />
              </SHStack>
            )}
          </SHStack>
          <SHStack>
            <SHTypography
              variant="subtitle2"
              colorVariant="third"
              sx={{
                padding: 0,
              }}
            >
              {userInfo?.preferredContactHours
                ? "Preferred contact hours"
                : null}
            </SHTypography>
            <SHTypography
              variant="body3"
              colorVariant="third"
              sx={{
                padding: 0,
                textAlign: "justify",
              }}
            >
              {userInfo?.preferredContactHours}
            </SHTypography>
          </SHStack>
        </SHStack>
        <SHStack width={"25%"} alignItems={"center"} spacing={1}>
          <SHAvatar width="80px" height="80px" src={`${userInfo?.avatarUrl}`} />
          {userInfo?.linkedinProfileUrl && (
            <SHChip
              label={
                <Link
                  variant="body1"
                  component="a"
                  underline="none"
                  sx={{
                    fontSize: 13,
                    fontWeight: 600,
                    cursor: "pointer",
                    color: palette.text.secondary,
                  }}
                  onClick={() => window.open(`${userInfo?.linkedinProfileUrl}`)}
                >
                  LinkedIn
                </Link>
              }
              sx={{
                width: 66,
                height: 22,
                padding: "1px 6px",
                boxSizing: "border-box",
                background: `${hexToRGBA("#e9ebfd", 0.25)}`,
                border: `1px solid ${palette.secondary[100]}`,
                boxShadow: shadows[1],
                "& >.MuiChip-label": {
                  overflow: "unset",
                  paddingTop: "2px",
                },
              }}
            />
          )}
        </SHStack>
      </SHStack>
      <SHStack
        sx={{
          position: "absolute",
          top: "190px",
        }}
      >
        <SHTypography
          variant="subtitle2"
          colorVariant="third"
          sx={{
            padding: 0,
          }}
        >
          {!isEmpty(userInfo?.regions) ? "Region" : null}
        </SHTypography>
        <Truncate
          lines={
            defaultTo(regionDisplayName?.length, 0) >
              MAX_NUM_OF_EMAIL_CHARACTERS_DISPLAY && 1
          }
          ellipsis={
            <Link
              ref={labelRef}
              variant="body1"
              component="label"
              underline="none"
              sx={{
                paddingLeft: 1,
                fontWeight: 600,
                cursor: "pointer",
                color: palette.text.secondary,
              }}
              onClick={handleOnShowMore}
            >
              Show all...
            </Link>
          }
          style={{
            fontSize: 14,
            textAlign: "justify",
            color: palette.text.third,
            lineHeight: "160%",
            fontWeight: 400,
            wordBreak: "break-all",
          }}
        >
          {regionDisplayName}
        </Truncate>
        <SHPopper
          anchorEl={anchorEl}
          open={open}
          placement="top-start"
          popperOptions={{
            modifiers: [{ name: "flip", enabled: false }],
          }}
        >
          <SHBox pb={1}>
            <SHStack
              ref={wrapperRef}
              sx={{
                p: 1,
                pr: 4,
                maxWidth: "365px",
                minHeight: "60px",
                bgcolor: hexToRGBA(palette.common.white, 0.85),
                border: "1px solid #E3E3E3",
                backdropFilter: "blur(2px)",
                borderRadius: "2px",
                position: "relative",
              }}
            >
              <SHTypography
                variant="body2"
                zIndex="1"
                colorVariant="third"
                sx={{
                  textAlign: "justify",
                }}
              >
                {userInfo?.regions?.join(", ")}
              </SHTypography>
              <SHBox sx={{ position: "absolute", top: "10px", right: "10px" }}>
                <CloseButton onClick={handleOnShowMore} />
              </SHBox>
              <SHBox sx={{ position: "absolute", bottom: "-11.7px", left: 0 }}>
                <TooltipAllowSVG />
              </SHBox>
            </SHStack>
          </SHBox>
        </SHPopper>
      </SHStack>
    </SHStack>
  );
};
