import {
  loadInvestmentProductBrandingDataThunk,
  loadInvestmentProductESGDataThunk,
  loadInvestmentProductManagerThunk,
  loadInvestmentProductSeriesThunk,
  loadInvestmentProductModelsThunk
} from "@redux/slices/product/investment-product";
import { useAppDispatch } from "@redux/store";
import { useNotification } from "./useNotification";

export const useInvestmentProduct = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();

  const loadInvestmentProductManager = async (
    investmentProductId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadInvestmentProductManagerThunk({ investmentProductId, mode }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response?.data;
  };

  const loadInvestmentProductSeries = async (
    investmentProductId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadInvestmentProductSeriesThunk({ investmentProductId, mode }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response?.data;
  };

  const loadInvestmentProductModels = async (
    investmentProductId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadInvestmentProductModelsThunk({ investmentProductId, mode }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response?.data;
  };

  const loadInvestmentProductEsg = async (
    investmentProductId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadInvestmentProductESGDataThunk({ investmentProductId, mode }),
    ).unwrap();

    if (![response, response.isSuccess, response.data].every(Boolean)) {
      notify(response.message, {
        variant: "error",
        close: true,
      });

      return;
    }

    return response.data;
  };

  const loadInvestmentProductBrandingInfo = async (productId: string) => {
    const response = await dispatch(
      loadInvestmentProductBrandingDataThunk(productId),
    ).unwrap();

    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  return {
    loadInvestmentProductManager,
    loadInvestmentProductSeries,
    loadInvestmentProductModels,
    loadInvestmentProductBrandingInfo,
    loadInvestmentProductEsg,
  };
};
