import { APIExtRoutes } from "@constants";
import { InvestmentProductBannerInfoDTO } from "@models/product/investment-product/entities/bannerInfo";
import { InvestmentProductBrandingDataDTO } from "@models/product/investment-product/entities/brandingData";
import { InvestmentProductEsgDataDTO } from "@models/product/investment-product/entities/esg";
import { InvestmentConfigurationGroup } from "@models/product/investment-product/entities/investmentProductTabs";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { InvestmentProductModelsDTO } from "@models/product/investment-product/entities/model";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getInvestmentProductESGData,
  getInvestmentProductManager,
  getInvestmentProductSeries,
  getProductManagedBrandingInfo,
  getInvestmentProductModels,
} from "@services/product/investment-product/investmentProductService";
import { investmentProductTabsData } from "@services/product/investment-product/investmentProductTabData";

interface InvestmentProductStore {
  investmentProductTabs: InvestmentConfigurationGroup[];
  investmentProductUI: {
    isEditMode?: boolean;
    isLoading?: boolean;
    isManagerDataLoading?: boolean;
    isSeriesDataLoading?: boolean;
    isModelsDataLoading?: boolean;
    isEsgDataLoading?: boolean;
    isLoadingEditMode?: boolean;
    selectedTab: number;
  };
  investmentProductBannerInfo?: InvestmentProductBannerInfoDTO[];
  investmentProductBrandingData?: InvestmentProductBrandingDataDTO;
  investmentProductManagerData?: InvestmentProductManagerDTO;
  investmentProductSeriesData?: InvestmentProductSeriesDTO[];
  investmentProductModelsData?: InvestmentProductModelsDTO[];
  investmentProductEsgData?: InvestmentProductEsgDataDTO;
}

const initialState: InvestmentProductStore = {
  investmentProductTabs: investmentProductTabsData,
  investmentProductUI: {
    isEditMode: false,
    isLoading: undefined,
    isManagerDataLoading: undefined,
    isSeriesDataLoading: undefined,
    isModelsDataLoading: undefined,
    isEsgDataLoading: undefined,
    isLoadingEditMode: false,
    selectedTab: 0,
  },
  investmentProductBannerInfo: undefined,
  investmentProductBrandingData: undefined,
  investmentProductManagerData: undefined,
  investmentProductSeriesData: undefined,
  investmentProductModelsData: undefined
};

const investmentProductSlice = createSlice({
  name: "investmentProduct",
  initialState,
  reducers: {
    updateSelectedTab: (state, action: PayloadAction<number>) => {
      state.investmentProductUI.selectedTab = action.payload;
    },
    updateInvestmentProductManagerData: (
      state,
      action: PayloadAction<InvestmentProductManagerDTO | undefined>,
    ) => {
      state.investmentProductManagerData = action.payload;
    },
    updateInvestmentProductSeriesData: (
      state,
      action: PayloadAction<InvestmentProductSeriesDTO[] | undefined>,
    ) => {
      state.investmentProductSeriesData = action.payload;
    },
    updateInvestmentProductModelsData: (state, action: PayloadAction<InvestmentProductSeriesDTO[] | undefined>) => {
      state.investmentProductModelsData = action.payload;
    },
    updateInvestmentProductEsgData: (
      state,
      action: PayloadAction<InvestmentProductManagerDTO | undefined>,
    ) => {
      state.investmentProductEsgData = action.payload;
    },
  },
  extraReducers(builder) {
    //Load Product Managed Account - Manager
    builder.addCase(
      loadInvestmentProductManagerThunk.pending,
      (state, action) => {
        state.investmentProductUI.isManagerDataLoading = true;
      },
    );
    builder.addCase(
      loadInvestmentProductManagerThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isManagerDataLoading = false;
        state.investmentProductManagerData = action.payload?.isSuccess
          ? action.payload?.data
          : undefined;
      },
    );
    builder.addCase(
      loadInvestmentProductManagerThunk.rejected,
      (state, action) => {
        state.investmentProductUI.isManagerDataLoading = false;
      },
    );

    builder.addCase(
      loadInvestmentProductBrandingDataThunk.pending,
      (state, action) => {
        state.investmentProductUI.isLoading = true;
      },
    );
    builder.addCase(
      loadInvestmentProductBrandingDataThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isLoading = false;
        state.investmentProductBrandingData = action.payload?.data;
      },
    );
    builder.addCase(
      loadInvestmentProductBrandingDataThunk.rejected,
      (state, action) => {
        state.investmentProductUI.isLoading = false;
      },
    );

    builder.addCase(
      loadInvestmentProductESGDataThunk.pending,
      (state, action) => {
        state.investmentProductUI.isEsgDataLoading = true;
      },
    );
    builder.addCase(
      loadInvestmentProductESGDataThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isEsgDataLoading = false;
        state.investmentProductEsgData = action.payload?.isSuccess
          ? action.payload?.data
          : undefined;
      },
    );
    builder.addCase(
      loadInvestmentProductESGDataThunk.rejected,
      (state, action) => {
        state.investmentProductUI.isEsgDataLoading = false;
      },
    );
    builder.addCase(
      loadInvestmentProductSeriesThunk.pending,
      (state, action) => {
        state.investmentProductUI.isSeriesDataLoading = true;
      },
    );
    builder.addCase(
      loadInvestmentProductSeriesThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isSeriesDataLoading = false;
        state.investmentProductSeriesData = action.payload?.data;
      },
    );
    builder.addCase(
      loadInvestmentProductSeriesThunk.rejected,
      (state, action) => {
        state.investmentProductUI.isSeriesDataLoading = false;
      },
    );
    builder.addCase(
      loadInvestmentProductModelsThunk.pending,
      (state, action) => {
        state.investmentProductUI.isModelsDataLoading = true;
      }
    );
    builder.addCase(
      loadInvestmentProductModelsThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isModelsDataLoading = false;
        state.investmentProductModelsData = action.payload.data;
      }
    );
    builder.addCase(
      loadInvestmentProductModelsThunk.rejected,
      (state, action) => {
        state.investmentProductUI.isModelsDataLoading = false;
      }
    )
  },
});

export const {
  updateSelectedTab,
  updateInvestmentProductManagerData,
  updateInvestmentProductSeriesData,
  updateInvestmentProductModelsData,
  updateInvestmentProductEsgData,
} = investmentProductSlice.actions;

//Thunk functions
export const loadInvestmentProductManagerThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsManager,
  async ({
    investmentProductId,
    mode,
  }: {
    investmentProductId?: string;
    mode?: "view" | "edit";
  }) => {
    return await getInvestmentProductManager(investmentProductId, mode);
  },
);

export const loadInvestmentProductBrandingDataThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsBrandingData,
  async (investmentProductId: string) => {
    return await getProductManagedBrandingInfo(investmentProductId);
  },
);

export const loadInvestmentProductESGDataThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsESG,
  async ({
    investmentProductId,
    mode,
  }: {
    investmentProductId?: string;
    mode?: "view" | "edit";
  }) => {
    return await getInvestmentProductESGData(investmentProductId, mode);
  },
);

export const loadInvestmentProductSeriesThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsSeries,
  async ({
    investmentProductId,
    mode,
  }: {
    investmentProductId?: string;
    mode?: "view" | "edit";
  }) => {
    return await getInvestmentProductSeries(investmentProductId, mode);
  },
);

export const loadInvestmentProductModelsThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsModels,
  async ({
    investmentProductId,
    mode,
  }: {
    investmentProductId?: string;
    mode?: "view" | "edit";
  }) => {
    return await getInvestmentProductModels(investmentProductId, mode);
  }
);

export default investmentProductSlice.reducer;
