import { DuplicateSVG } from "@components/svgs";
import { useNotification } from "@hooks/useNotification";
import { IconButton, Tooltip, tooltipClasses, useTheme } from "@mui/material";
import shadows from "@mui/material/styles/shadows";
import { defaultTo } from "lodash";

export interface CopyButtonProps {
  width?: string;
  height?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  disabled?: boolean;
  tooltipTitle?: string;
  copyValue?: string | null;
  notifyContent?: string;
  [key: string]: any;
}

export const CopyButton = ({
  width = "20px",
  height = "20px",
  isLoading = false,
  disabled = false,
  tooltipTitle,
  copyValue,
  notifyContent,
  ...others
}: CopyButtonProps) => {
  const { palette } = useTheme();
  const { notify } = useNotification();

  return (
    <Tooltip
      title={tooltipTitle}
      enterDelay={0}
      enterNextDelay={100}
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
              {
                marginTop: "3px",
              },
          },
        },
      }}
    >
      <IconButton
        {...others}
        disableRipple
        disabled={disabled}
        sx={{
          width,
          height,
          p: 0,
          backgroundColor: palette.common.white,
          border: `1px solid ${palette.common.black}`,
          boxShadow: shadows[2],
          "&:hover": {
            borderColor: palette.primary[800],
            boxShadow: "none",
          },
          "&:active": {
            backgroundColor: palette.primary.dark,
            borderColor: palette.common.black,
            boxShadow: `inset 0 4px 4px ${palette.primary[900]}`,
            "&>svg>path": {
              fill: palette.common.white,
            },
          },
          "&:disabled": {
            border: `1px solid ${palette.text.disabled}`,
            backgroundColor: palette.common.white,
            "&>svg>path": {
              fill: palette.text.disabled,
            },
          },
        }}
        onClick={() => {
          if (!Boolean(copyValue)) return;
          const valueToWrite = defaultTo(copyValue, "");
          navigator.clipboard.writeText(valueToWrite);

          notify(notifyContent, {
            variant: "success",
            close: true,
          });
        }}
      >
        <DuplicateSVG width={10} />
      </IconButton>
    </Tooltip>
  );
};
