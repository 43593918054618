import {
  SHBox,
  SHDivider,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { getPercentageValue } from "@pages/suppliers/_id/util";
import { Fragment, useMemo, useState } from "react";
import { Cell, Pie, PieChart, Sector } from "recharts";
import {
  chartBorderBottomColor,
  chartColors,
  cx,
  cy,
  endAngle,
  outerRadius,
  pieChartHeight,
  pieChartWidth,
  pieHeight,
  pieWidth,
  startAngle,
} from "../../configs";
import "./index.scss";

export interface ChartData {
  name: string;
  value: number;
}

interface SHPieChartProps {
  chartData: ChartData[];
}

export const SHPieChart = ({ chartData }: SHPieChartProps) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>();
  const [nameSelected, setNameSelected] = useState<string | undefined>();
  const [ratioSelected, setRatioSelected] = useState<string | undefined>();
  const [circleNote, setCircleNote] = useState<string | undefined>();
  const chartLegends = useMemo(
    () =>
      chartData.map((element, index) => (
        <Fragment key={element.name}>
          <SHStack direction="row" spacing={2} alignItems="center" width="100%">
            <SHBox
              sx={{
                backgroundColor: chartColors[element.name.toLowerCase()],
                width: "20px",
                height: "20px",
              }}
            />
            <SHTypography variant="body1">{element.name}</SHTypography>
            <SHTypography variant="body1" marginLeft="auto !important">
              {getPercentageValue(element.value)}%
            </SHTypography>
          </SHStack>
          <SHDivider sx={{ borderBottom: chartBorderBottomColor }} />
        </Fragment>
      )),
    [chartData],
  );

  const renderActiveShape = (props: any) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
    } = props;
    setNameSelected(payload.name);
    setRatioSelected(`(Rate ${getPercentageValue(Number(payload.value))}%)`);
    setCircleNote(fill);

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  const ShowLabel = (e: any, index: number) => {
    setActiveIndex(index);
  };

  const OnMouseLeave = () => {
    setActiveIndex(undefined);
  };

  return (
    <SHStack minHeight="353px" direction="row">
      <SHStack>
        <PieChart
          className="pie-chart-custom"
          width={pieChartWidth}
          height={pieChartHeight}
        >
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            width={pieWidth}
            height={pieHeight}
            data={chartData}
            startAngle={startAngle}
            endAngle={endAngle}
            dataKey="value"
            nameKey="name"
            cx={cx}
            cy={cy}
            outerRadius={outerRadius}
            onMouseEnter={ShowLabel}
            onClick={ShowLabel}
            onMouseLeave={OnMouseLeave}
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={chartColors[entry.name.toLowerCase()]}
              />
            ))}
          </Pie>
        </PieChart>
        {activeIndex !== undefined && (
          <SHStack direction="row" justifyContent="center">
            <SHBox
              sx={{
                backgroundColor: circleNote,
                borderRadius: "50%",
                width: "6px",
                height: "6px",
                marginTop: "7px",
                marginRight: "6px",
              }}
            />
            <SHStack>
              <SHTypography
                sx={{ fontSize: "12px", color: "#333" }}
                padding={0}
              >
                {nameSelected}
              </SHTypography>
              <SHTypography
                sx={{ fontSize: "10px", color: "#333" }}
                padding={0}
                variant="body1"
              >
                {ratioSelected}
              </SHTypography>
            </SHStack>
          </SHStack>
        )}
      </SHStack>

      <SHStack direction="column" spacing={1} width="70%" paddingTop="10px">
        {chartLegends}
      </SHStack>
    </SHStack>
  );
};
