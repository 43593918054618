import { urlRegex } from "@constants";
import { InvestmentProductConfigurationModelsFeature } from "@models/product/investment-product/entities/model";
import { SupplierDTO } from "@models/supplier/entities/suppliers";
import { isEmpty, isNil } from "lodash";
import * as Yup from "yup";

export const supplierValidators = Yup.object().shape({
  companyName: Yup.string()
    .required("Company name is required")
    .max(100, "Company name must be less than 100 characters"),
  website: Yup.string()
    .required("Website is required")
    .matches(urlRegex, "Please enter URL")
    .max(2048, "URL must be less than 2048 characters"),
  companyLogo: Yup.string().nullable(true).required("Logo is required"),
  email: Yup.string().nullable(true).email("Email is not valid"),
  afsl: Yup.string()
    .nullable(true)
    .when("isLicensee", (isLicensee, schema) => {
      return isLicensee ? schema.required("AFSL number is required") : schema;
    }),
});

export const generateFormTitle = (name?: string) => {
  if (!name || isEmpty(name)) return "Supplier";
  return name;
};

export const supplierPayloadConverter = (
  supplier: SupplierDTO,
): SupplierDTO => {
  const newSupplier = { ...supplier };
  if (isEmpty(newSupplier.email)) delete newSupplier.email;
  return newSupplier;
};

export const supplierGetConverter = (supplier: SupplierDTO): SupplierDTO => {
  const newSupplier = { ...supplier };
  if (isNil(newSupplier.email)) newSupplier.email = "";
  return newSupplier;
};

export const generateSubTitle = (isMyProfile?: boolean) => {
  if (isMyProfile)
    return {
      Profile: "Manage your SuitabilityHub profile",
      ProfilePhoto: "Help fellow team members recognise your account",
      PersonalDetails: "Set the details of your account",
      ChangePassword: "Change my password",
      AssociatedProduct:
        "Associated products can only be changed in the user management module",
      AccountType:
        "Account type can only be changed in the user management module",
      AdditionalPermissions:
        "Additional permissions can only be changed in the user management module",
    };
  return {
    Profile: "Manage this user’s SuitabilityHub profile",
    ProfilePhoto: "Help fellow team members recognise this account",
    PersonalDetails: "Set the user details of this account",
    ChangePassword: "Send user password reset email",
    AssociatedProduct:
      "Choose which products this user is linked to. Standard users will be able to manage and/or make posts for these products if they have permission. Sales users will be featured on the “Find BDM” page of these products",
    AccountType:
      "Users must be assigned a licence. These determine their core permissions",
    AdditionalPermissions:
      "Select any other permissions you’d like this user to access. By default, standard users can view platform profiles, run platform analysis and view the news & updates tab. They need to be granted the permission to edit information relating to your business or products",
  };
};

export const generateTitle = (isMyProfile?: boolean) => {
  if (isMyProfile)
    return {
      AdditionalPermissions: "Additional permissions",
    };
  return {
    AdditionalPermissions: "Additional permissions (Optional)",
  };
};
export const getPercentageValue = (percent?: string | number) => {
  if (!percent) return undefined;
  const numericValue =
    typeof percent === "string" ? parseFloat(percent) : percent;
  return (numericValue * 100).toFixed(1);
};

export const getProductDataValue = (
  features?: InvestmentProductConfigurationModelsFeature[] | null,
  featureName?: string,
) => {
  if (!features || !featureName) return;
  const feature = features.find(
    (feature) => feature.name?.toLowerCase() === featureName.toLowerCase(),
  );

  return feature?.productData?.value;
};
export const tryParseJson = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};
