// Pie chart configs
export const chartColors: { [key: string]: any } = {};
chartColors["cash"] = "#211da2";
chartColors["australian fixed interest"] = "#007dff";
chartColors["international fixed interest"] = "#6db5ff";
chartColors["australian equities"] = "#07a274";
chartColors["international equities"] = "#0ec48f";
chartColors["property and infrastructure"] = "#f4b25a";
chartColors["alternatives"] = "#ef56a1";
chartColors["other"] = "#838a98";

export const chartTitleColor = "#515b6F";
export const chartBorderBottomColor = "1px dotted #07a274";
export const pieChartWidth = 250;
export const pieChartHeight = 250;
export const pieWidth = 300;
export const pieHeight = 250;
export const outerRadius = 100;
export const cx = "50%";
export const cy = "55%";
export const startAngle = 90;
export const endAngle = -270;

// Gauge Chart configs
export const colorLevels = [
  { value: 1, label: "Very low", color: "#221DA2", fontColor: "#FFFFFF" },
  { value: 2, label: "Low", color: "#6F6FE2", fontColor: "#FFFFFF" },
  { value: 3, label: "Low to medium", color: "#A6A8F0", fontColor: "#000000" },
  { value: 4, label: "Medium", color: "#C9BDFF", fontColor: "#000000" },
  { value: 5, label: "Medium to high", color: "#A4D1FF", fontColor: "#000000" },
  { value: 6, label: "High", color: "#6DB5FF", fontColor: "#FFFFFF" },
  { value: 7, label: "Very high", color: "#007DFF", fontColor: "#FFFFFF" },
];
// SinglePercentageBarChart configs
export type PercentageChartColor = {
  leftColor: string;
  rightColor: string;
  leftLabel: string;
  rightLabel: string;
};
export const PercentageChartMetaData: Record<string, PercentageChartColor> = {
  TargetExposure: {
    leftColor: "#07A274",
    rightColor: "#6FDCBC",
    leftLabel: "Growth",
    rightLabel: "Defensive",
  },
  ActivePassiveShare: {
    leftColor: "#FF753C",
    rightColor: "#F4B25A",
    rightLabel: "Active",
    leftLabel: "Passive",
  },
};
// Investment table configs
export const defaultCellWidth = 20;
export const defaultFirstCellWidth = 30;
export const defaultNumOfCell = 1;
export const defaultNumOfHeadCol = 1;
export const headerPadding = 15;
export const defaultTextLength = 8;
