import { ImageSVG } from "@components/svgs";
import { PageRoutes } from "@constants";
import { generateFeatureName } from "@utils/text-format";
import { UserType } from "@models/auth";
import {
  ConfigurationSection,
  FieldTypeId,
  SectionDisplayTypeId,
} from "@models/configuration";
import { SHBox, SHDivider, SHHtmlBlock, SHStack, SHTypography } from "@components/design-systems";
import { Link, useTheme } from "@mui/material";
import { generatePath, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { InformationButton } from "@components/buttons/information";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import React from "react";
import { toNativeValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { SHCollapse } from "@components/design-systems/sh-collapse";
import { ImageGalleryBox } from "@components/image-gallery";
import { SHBooleanIcon } from "@components/design-systems/sh-boolean-icon";
import { FeatureViewLongText } from "../feature-view-long-text";
import SidePanelSection from "@components/side-panel-section";
import { InvestmentProductConfigurationFeature, InvestmentProductConfigurationSection } from "@models/product/investment-product/entities/investmentProduct";

interface ViewStandardProps {
  sections: InvestmentProductConfigurationSection[] | undefined,
  isLoadingProductData?: Boolean,
  investmentProductUpdateDate: Date | undefined
}

export const ViewStandard = ({
  sections = undefined,
  isLoadingProductData = false,
  investmentProductUpdateDate
}: ViewStandardProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);

  const mainBodySections = sections?.filter(
    (section) =>
      section.displayType?.id === SectionDisplayTypeId.MainBodyPage,
  );

  const serviceBodySections = sections?.filter(
    (section) =>
      section.displayType?.id === SectionDisplayTypeId.Feature,
  );

  const sidePanelSections = sections?.filter(
    (section) =>
      section.displayType?.id === SectionDisplayTypeId.SidePanel
      || section.displayType?.id === SectionDisplayTypeId.SidePanelRow,
  );

  const renderFeatureItem = (feature: InvestmentProductConfigurationFeature) => {
    const featureName = generateFeatureName(feature.name ?? "", feature.description);
    const isLongText = feature.fieldType?.id === FieldTypeId.TextLong;

    const getFeatureValue = () => {
      const value = feature.productData?.value;
      if (feature.fieldType?.id === FieldTypeId.YesNo) {
        switch (value) {
          case "true":
            return <SHBooleanIcon isTrue />;
          case "false":
            return <SHBooleanIcon isTrue={false} />;
        }
      }
      if (!value) {
        return (
          <SHTypography
            variant="body3"
            colorVariant="third"
            key={feature.id}
          >
            No data
          </SHTypography>
        );
      }

      return (
        <SHHtmlBlock
          variant="body3"
          color={palette.text.third}
          content={value}
          key={feature.id}
        />
      );
    };

    if (!sections) {
      return <></>;
    }

    switch (feature.fieldType?.id) {
      case FieldTypeId.TextLong: {
        return (
          <SHStack key={feature.id}>
            <SHStack
              direction={isLongText ? "column" : "row"}
              justifyContent="space-between"
              sx={{
                py: "15px",
                wordBreak: "break-word",
              }}
            >
              <SHTypography
                variant="subtitle2"
                textAlign="left"
                width="45%"
                color="#25324B"
              >
                {featureName.first}
                {feature?.description && (
                  <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                    {featureName.last}
                    <InformationButton
                      content={feature.description}
                      margin={"-8px 0px 2px 4px !important"}
                    />
                  </SHBox>
                )}
              </SHTypography>

              <SHBox>{getFeatureValue()}</SHBox>
            </SHStack>
            <SHDivider />
          </SHStack>
        );
      }

      case FieldTypeId.YesNo: {
        const additionalValue = feature.productData?.additionalValue;
        const hasDescription = feature.productData?.value === "true" && additionalValue;

        const renderFeatureHeading = () => (
          <SHStack
            direction={"row"}
            justifyContent="space-between"
            sx={{ pr: "40px", py: "25px" }}
          >
            <SHStack direction="row" spacing={0.5} alignItems="start">
              <SHTypography color="#25324B">{feature.name}</SHTypography>
              {feature?.description && (
                <InformationButton content={feature.description} />
              )}
            </SHStack>
            <SHBox>{getFeatureValue()}</SHBox>
          </SHStack>
        );

        if (!hasDescription) {
          return (
            <>
              {renderFeatureHeading()}
              <SHDivider />
            </>
          );
        }

        return (
          <>
            <SHCollapse
              title={renderFeatureHeading()}
              open
              children={<FeatureViewLongText content={additionalValue} />}
              fullWidthTitle
            />
            <SHDivider />
          </>
        );
      }
    }
  };

  const renderMainBody = () => {
    if (isLoadingProductData) {
      return (
        <SHStack spacing={3}>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"40%"} />
            <SHSkeleton height={20} width={"60%"} />
            <SHSkeleton height={20} width={"100%"} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"40%"} />
            <SHSkeleton height={20} width={"60%"} />
            <SHSkeleton height={20} width={"100%"} />
          </SHStack>
        </SHStack>
      );
    }

    return (
      <SHStack spacing={2}>
        {mainBodySections?.map((section, index) => (
          <SHStack key={index}>
            <SHStack direction="row" spacing={"5px"} alignItems="start">
              {section.iconUrl && (
                <img
                  src={section.iconUrl}
                  width={24}
                  height={24}
                  alt={section.iconUrl}
                />
              )}

              <SHTypography variant="subtitle1" sx={{ color: "#25324B" }}>{section.name}</SHTypography>
              {section.description && (
                <InformationButton content={section.description} />
              )}
            </SHStack>
            {section.features?.map((feature) => renderFeatureItem(feature))}
          </SHStack>
        ))}

        {serviceBodySections?.map((section, index) => (
          <SHStack key={index}>
            <SHStack direction="row" spacing={"5px"} alignItems="start">
              {section.iconUrl && (
                <img
                  src={section.iconUrl}
                  width={24}
                  height={24}
                  alt={section.iconUrl}
                />
              )}

              <SHTypography variant="subtitle2">{section.name}</SHTypography>
              {section.description && (
                <InformationButton content={section.description} />
              )}
            </SHStack>
            {section.features?.map(
              (feature: InvestmentProductConfigurationFeature) => (
                <React.Fragment key={feature.id}>
                  {renderServiceItem(feature)}
                </React.Fragment>
              ),
            )
            }
          </SHStack>
        ))}
      </SHStack>
    );
  };

  const renderServiceItem = (feature: InvestmentProductConfigurationFeature) => {
    const isSlideShow = feature.fieldType?.id === FieldTypeId.Slideshow;

    const value = toNativeValue(
      feature.productData?.value || "",
      isSlideShow ? FieldTypeId.Slideshow : FieldTypeId.YesNo,
    );
    const additionalValue = feature.productData?.additionalValue;
    const hasDescription = isSlideShow || (value === true && additionalValue);

    if (isSlideShow && serviceBodySections) {
      if (!value || value.length === 0) {
        return <></>;
      }

      return (
        <SHCollapse
          sx={{ pt: "25px" }}
          open
          title={
            <SHStack direction="row" spacing={0.5} alignItems="start">
              <ImageSVG />
              <SHTypography>{feature.name}</SHTypography>
            </SHStack>
          }
          children={
            <SHBox sx={{ pt: "25px" }}>
              <ImageGalleryBox
                title={serviceBodySections[0].name}
                initialImages={value}
                width="100%"
              />
            </SHBox>
          }
        />
      );
    }

    const renderFeatureValue = () => {
      switch (value) {
        case true:
          return <SHBooleanIcon label={"Available"} isTrue />;
        case false:
          return <SHBooleanIcon label={"Not available"} isTrue={false} />;
        default:
          return (
            <SHTypography
              variant="body2"
              colorVariant="third"
              sx={{
                minWidth: "54px",
              }}
            >
              No data
            </SHTypography>
          );
      }
    };

    const renderFeatureHeading = () => (
      <SHStack
        direction={"row"}
        justifyContent="space-between"
        sx={{ pr: "40px", py: "25px" }}
      >
        <SHStack direction="row" spacing={0.5} alignItems="start">
          <SHTypography>{feature.name}</SHTypography>
          {feature?.description && (
            <InformationButton content={feature.description} />
          )}
        </SHStack>
        <SHBox width={130}>{renderFeatureValue()}</SHBox>
      </SHStack>
    );

    if (!hasDescription) {
      return (
        <>
          {renderFeatureHeading()}
          <SHDivider />
        </>
      );
    }

    return (
      <>
        <SHCollapse
          title={renderFeatureHeading()}
          open
          children={<FeatureViewLongText content={additionalValue} />}
          fullWidthTitle
        />
        <SHDivider />
      </>
    );
  };

  const renderSidePanel = () => {
    if (isLoadingProductData) {
      return (
        <SHStack spacing={3}>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"100%"} />
            <SHSkeleton height={20} width={"60%"} />
            <SHSkeleton height={20} width={"60%"} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"100%"} />
            <SHSkeleton height={20} width={"60%"} />
            <SHSkeleton height={20} width={"60%"} />
          </SHStack>
        </SHStack>
      );
    }

    const renderPageUpdate = () => {
      if (investmentProductUpdateDate) {
        const year = investmentProductUpdateDate.getFullYear() % 100;
        const month = (investmentProductUpdateDate.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = investmentProductUpdateDate.getDay().toString().padStart(2, '0');

        return (
          <SHTypography
            variant="body1"
            colorVariant="third"
            textAlign={"right"}
          >
            Page updated: {`${day}/${month}/${year}`}
          </SHTypography>
        );
      } else {
        return <></>
      }
    }

    return (
      <SHStack spacing={2}>
        {renderPageUpdate()}
        {sidePanelSections?.map((section, index) => (
          <SidePanelSection key={index} section={{ ...section, sectionDisplayType: section.displayType } as unknown as ConfigurationSection} />
        ))}
      </SHStack>
    );
  };

  const showUpgradeToView = () => {
    if (!isLoadingProductData && !sections) {
      if (
        user?.userType === UserType.AdviserTrial ||
        user?.userType === UserType.AdviserAdminTrial
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <SHStack>
      {showUpgradeToView() ? (
        <SHStack
          display={"flex"}
          gridTemplateColumns={"6.6fr 3.25fr"}
          marginTop={"25px"}
          gap={"15px"}
        >
          <SHTypography variant="subtitle2">
            Please upgrade your subscription
            <Link
              color={palette.text.primary}
              component="a"
              underline="always"
              onClick={() => {
                navigate(
                  `${generatePath(PageRoutes.practiceOverview, {
                    practiceId: user?.userMetadata?.adviser_firm_id,
                  })}/subscription`,
                );
              }}
              sx={{
                paddingX: 0.5,
                cursor: "pointer",
                textDecorationColor: palette.text.primary,
              }}
            >
              here
            </Link>
            to view detailed information, ESG policies, and features for this
            platform
          </SHTypography>
        </SHStack>
      ) : (
        <SHStack
          display={"grid"}
          gridTemplateColumns={"6.6fr 3.25fr"}
          gap={"15px"}
        >
          {renderMainBody()}
          {renderSidePanel()}
        </SHStack>
      )}
    </SHStack>
  );
}