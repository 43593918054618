import { SHImage, SHStack, SHTypography } from "@components/design-systems";
import { CheckSVG, CrossSVG, WarningSVG } from "@components/svgs";
import { ManagedAccountFieldType } from "@models/managed-accounts/enums/fieldType";
import { ManagedAccountFieldTypeValue } from "@models/managed-accounts/enums/fieldTypeValue";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { useTheme } from "@mui/material";
import { RenderNumericFeesValue } from "@pages/managed-accounts/_id/steps/fee-analysis/components/tables/table-cell";
import { TextOverflowEllipsis } from "@pages/platform-analysis/components/text-overflow-ellipsis";
import { InvestmentDataDTO } from "../../model";
import { isEmpty } from "lodash";
import { MultipleInformationButton } from "@pages/managed-accounts/_id/steps/fee-analysis/components/buttons/multiple-information";
import { Variant } from "@mui/material/styles/createTypography";

export const SMAModelCell = ({
  name,
  code,
  managerName,
}: {
  name: string;
  code: string;
  managerName: string;
}) => {
  const { palette } = useTheme();
  const renderTitle = () => {
    const modelName = `${managerName} ${name} ${code}`;

    return modelName.length >= 64 ? modelName : "";
  };

  return (
    <SHTypography
      variant="body2"
      lineHeight={1.2}
      fontSize={13}
      fontWeight={700}
      title={renderTitle()}
      sx={{
        display: "-webkit-box",
        boxAlign: "center",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {`${managerName} ${name} `}
      <span
        style={{ fontSize: 13, fontWeight: 400, color: palette.text.third }}
      >
        {code}
      </span>
    </SHTypography>
  );
};

export const SMAPlatformCell = ({
  productName,
  productLogoUrl,
  subProductName,
}: {
  productName: string;
  productLogoUrl: string;
  subProductName: string;
}) => {
  return (
    <SHStack direction={"row"} alignItems={"center"}>
      <SHImage
        width={"24px"}
        height={"24px"}
        style={{ objectFit: "contain" }}
        src={productLogoUrl}
      />
      <SHStack>
        <TextOverflowEllipsis
          value={productName}
          WebkitLineClamp="1"
          sx={{
            marginLeft: "5px",
            minWidth: "100%",
          }}
        />
        <TextOverflowEllipsis
          value={subProductName}
          WebkitLineClamp="1"
          sx={{
            marginLeft: "5px",
            minWidth: "100%",
          }}
        />
      </SHStack>
    </SHStack>
  );
};

export const SMAInvestmentCell = ({
  investmentData,
  feesDisplayStyle,
  variant = "body2",
}: {
  investmentData: InvestmentDataDTO;
  feesDisplayStyle?: FeesDisplayStyle;
  variant?: Variant;
}) => {
  switch (investmentData.fieldTypeId) {
    case ManagedAccountFieldType.Currency:
    case ManagedAccountFieldType.Percentage: {
      const numberDollar = parseFloat(investmentData.value ?? "");
      const numberPercentage = parseFloat(investmentData.valueInPercent ?? "");

      const renderFormatNumber = () => {
        if (!isNaN(numberDollar) && !isNaN(numberPercentage)) {
          return (
            <RenderNumericFeesValue
              value={investmentData.value}
              valuePercentage={investmentData?.valueInPercent}
              tableViewMode={feesDisplayStyle}
              variant={variant}
            />
          );
        } else if (!isNaN(numberDollar) && isNaN(numberPercentage)) {
          return (
            <RenderNumericFeesValue
              value={investmentData.value}
              valuePercentage={investmentData.value}
              tableViewMode={feesDisplayStyle}
              variant={variant}
            />
          );
        } else if (isNaN(numberDollar) && !isNaN(numberPercentage)) {
          return (
            <RenderNumericFeesValue
              value={investmentData?.valueInPercent}
              valuePercentage={investmentData?.valueInPercent}
              tableViewMode={feesDisplayStyle}
              variant={variant}
            />
          );
        } else {
          return investmentData.value;
        }
      };

      return (
        <SHStack direction="row" spacing={1}>
          {investmentData.isShowWarning && (
            <SHStack mt={"1px"}>
              <WarningSVG backgroundColor="#EB5757" iconColor="white" />
            </SHStack>
          )}
          <SHTypography variant={variant} fontSize="13px" lineHeight="120%">
            {renderFormatNumber()}
          </SHTypography>
        </SHStack>
      );
    }
    case ManagedAccountFieldType.Text: {
      return (
        <SHStack direction="row" spacing={1}>
          {!isEmpty(investmentData.tooltip) && (
            <MultipleInformationButton
              title={investmentData.tooltip?.header}
              data={investmentData.tooltip?.data}
            />
          )}

          {investmentData.isShowWarning && (
            <SHStack mt={"1px"}>
              <WarningSVG backgroundColor="#EB5757" iconColor="white" />
            </SHStack>
          )}

          <SHTypography variant={variant} fontSize="13px" lineHeight="120%">
            {investmentData.value}
          </SHTypography>
        </SHStack>
      );
    }
    case ManagedAccountFieldType.YesNo: {
      switch (investmentData.value) {
        case ManagedAccountFieldTypeValue.True: {
          return (
            <SHStack direction="row" spacing={1}>
              {investmentData.isShowWarning && (
                <SHStack mt={"1px"}>
                  <WarningSVG backgroundColor="#EB5757" iconColor="white" />
                </SHStack>
              )}
              <CheckSVG />
            </SHStack>
          );
        }
        case ManagedAccountFieldTypeValue.False: {
          return (
            <SHStack direction="row" spacing={1}>
              {investmentData.isShowWarning && (
                <SHStack mt={"1px"}>
                  <WarningSVG backgroundColor="#EB5757" iconColor="white" />
                </SHStack>
              )}
              <CrossSVG />
            </SHStack>
          );
        }
        default: {
          return (
            <SHStack direction="row" spacing={1}>
              {investmentData.isShowWarning && (
                <SHStack mt={"1px"}>
                  <WarningSVG backgroundColor="#EB5757" iconColor="white" />
                </SHStack>
              )}
              <SHTypography variant={variant} fontSize="13px" lineHeight="120%">
                {investmentData?.value}
              </SHTypography>
            </SHStack>
          );
        }
      }
    }
    case ManagedAccountFieldType.DateTime: {
      return (
        <SHStack direction="row" spacing={1}>
          {investmentData.isShowWarning && (
            <SHStack mt={"1px"}>
              <WarningSVG backgroundColor="#EB5757" iconColor="white" />
            </SHStack>
          )}
          <SHTypography variant={variant} fontSize="13px" lineHeight="120%">
            {investmentData.value}
          </SHTypography>
        </SHStack>
      );
    }
    default:
      return <></>;
  }
};
