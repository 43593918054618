import { InformationButton } from "@components/buttons/information";
import {
  SHBooleanField,
  SHBox,
  SHDivider,
  SHHtmlBlock,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHBooleanIcon } from "@components/design-systems/sh-boolean-icon";
import { SHCollapse } from "@components/design-systems/sh-collapse";
import { ImageGalleryBox } from "@components/image-gallery";
import { ImageSVG } from "@components/svgs";
import { ProductFeaturesLayout } from "@layouts/products/features";
import { UserType } from "@models/auth";
import {
  ChartTypeId,
  FieldTypeId,
  SectionDisplayTypeId,
} from "@models/configuration";
import {
  InvestmentProductConfigurationModelSection,
  InvestmentProductConfigurationModelsFeature,
  InvestmentProductModelsDTO,
} from "@models/product/investment-product/entities/model";
import { useTheme } from "@mui/material";
import { toNativeValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { ChartTitle } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/chart-title";
import { GaugeChart } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/gauge-chart";
import { InvestmentTableItem } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/investment-table-item";
import { SinglePercentageBarChart } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/single-percentage-bar-chart";
import { UpgradeSubscriptionNotice } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/upgrade-subscription-notice";
import { PercentageChartMetaData } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/configs";
import {
  getPercentageValue,
  getProductDataValue,
} from "@pages/suppliers/_id/util";
import { AddtionalVisualizeTable } from "@pages/suppliers/constant";
import { RootState } from "@redux/store";
import { lightPalette } from "@themes/light";
import { generateFeatureName } from "@utils/text-format";
import { isEmpty } from "lodash";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useToggle } from "react-use";
import { InvestmentProductCollapseMenu } from "./components/investment-collapse-menu";
import { ChartData, SHPieChart } from "./components/pie-chart";
import SectionTitle from "./components/sub-section-title";
interface ViewFeatureProps {
  investmentProductModelsData?: InvestmentProductModelsDTO[];
  isModelsDataLoading?: boolean;
}
/**
 * @description: render feature for view SMA models tab
 */
export const ViewFeatureModel = ({
  investmentProductModelsData,
  isModelsDataLoading,
}: ViewFeatureProps) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { palette } = useTheme();
  const [selectedIndex, setSelectedIndex] = useState<[number, number]>([0, 0]);
  const [isShowPieChart, togglePieChart] = useToggle(true);
  const selectedModel = useMemo(() => {
    return investmentProductModelsData?.[selectedIndex[0]]?.models?.[
      selectedIndex[1]
    ];
  }, [investmentProductModelsData, selectedIndex]);
  const getInvestmentApproachChartData = (
    section: InvestmentProductConfigurationModelSection,
  ) => {
    const standardRiskMeasure = getProductDataValue(
      section.features,
      "Standard risk measure",
    );
    const targetGrowthExposure = getProductDataValue(
      section.features,
      "Target growth exposure",
    );
    const targetDefensiveExposure = getProductDataValue(
      section.features,
      "Target defensive exposure",
    );
    const activeShare = getProductDataValue(section.features, "Active Share");
    const passiveShare = getProductDataValue(section.features, "Passive Share");
    return {
      standardRiskMeasure,
      targetGrowthExposure,
      targetDefensiveExposure,
      activeShare,
      passiveShare,
    };
  };

  const renderPieChart = (
    section: InvestmentProductConfigurationModelSection,
  ) => {
    if (!section.chartFeatures) return <></>;

    const chartData: ChartData[] = section.chartFeatures.map((feature) => {
      return {
        name: feature.name as string,
        value: parseFloat(
          (!feature.productData ? 0 : feature.productData.value) as string,
        ),
      };
    });

    return (
      <SHStack direction="column" spacing={2} height="100%">
        <SHPieChart chartData={chartData} />
      </SHStack>
    );
  };

  const renderFeatureItem = (
    feature: InvestmentProductConfigurationModelsFeature,
  ) => {
    const featureName = generateFeatureName(
      feature.name ?? "",
      feature.description,
    );

    const getFeatureValue = () => {
      if (!feature.productData) return <></>;

      const value = feature.productData?.value;

      if (feature.fieldType?.id === FieldTypeId.YesNo) {
        switch (value) {
          case "true":
            return <SHBooleanIcon isTrue />;
          case "false":
            return <SHBooleanIcon isTrue={false} />;
        }
      }

      if (!value) {
        return (
          <SHTypography variant="body3" colorVariant="third" key={feature.id}>
            No data
          </SHTypography>
        );
      }

      return (
        <SHHtmlBlock
          variant="body3"
          color={palette.text.third}
          content={value}
          key={feature.id}
        />
      );
    };

    switch (feature.fieldType?.id) {
      case FieldTypeId.TextLong: {
        return (
          <SHStack
            sx={{
              py: "15px",
              wordBreak: "break-word",
            }}
          >
            <SHTypography
              variant="subtitle2"
              textAlign="left"
              color={lightPalette.text?.primary}
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-8px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
            </SHTypography>

            <SHBox>{getFeatureValue()}</SHBox>
          </SHStack>
        );
      }

      case FieldTypeId.TextShort: {
        return (
          <SHStack
            sx={{
              py: "15px",
              wordBreak: "break-word",
            }}
          >
            <SHTypography
              variant="subtitle2"
              textAlign="left"
              color={lightPalette.text?.primary}
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-8px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
            </SHTypography>
            <SHBox>{getFeatureValue()}</SHBox>
          </SHStack>
        );
      }

      case FieldTypeId.DateTime: {
        return (
          <SHStack
            sx={{
              py: "15px",
              wordBreak: "break-word",
            }}
          >
            <SHTypography
              variant="subtitle2"
              textAlign="left"
              color={lightPalette.text?.primary}
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-8px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
            </SHTypography>

            <SHBox>{getFeatureValue()}</SHBox>
          </SHStack>
        );
      }

      case FieldTypeId.YesNo: {
        return <></>;
      }

      case FieldTypeId.Slideshow: {
        const value = toNativeValue(
          feature.productData?.value || "",
          FieldTypeId.Slideshow,
        );
        if (!value || value.length === 0) {
          return <></>;
        }
        return (
          <SHCollapse
            sx={{ pt: "25px" }}
            open
            title={
              <SHStack direction="row" spacing={0.5} alignItems="start">
                <ImageSVG />
                <SHTypography>{feature.name}</SHTypography>
              </SHStack>
            }
          >
            <SHBox sx={{ pt: "25px" }}>
              <ImageGalleryBox
                title={feature?.name}
                initialImages={value}
                width="100%"
              />
            </SHBox>
          </SHCollapse>
        );
      }
    }
  };
  const renderHasChartSection = (
    section: InvestmentProductConfigurationModelSection,
    width: number,
  ) => {
    return (
      <>
        <SHStack
          paddingTop={"12px"}
          width={"100%"}
          alignItems={"center"}
          flexDirection={"row"}
          justifyContent={isShowPieChart ? "space-between" : "flex-end"}
        >
          {isShowPieChart && <ChartTitle>{section.chartName}</ChartTitle>}
          <SHBooleanField
            onChange={togglePieChart}
            yesLabel="Chart"
            noLabel="Table"
            isSwitch
            value={isShowPieChart}
            width="80px"
            isShowClearButton={false}
          />
        </SHStack>
        <SHStack
          width={`${width}%`}
          paddingBottom={isShowPieChart ? 0 : "10px"}
        >
          {isShowPieChart ? (
            renderPieChart(section)
          ) : (
            <InvestmentTableItem section={section} width={width} />
          )}
        </SHStack>
      </>
    );
  };

  const renderSectionItem = (
    section: InvestmentProductConfigurationModelSection,
    index: number,
  ) => {
    const previousSection = selectedModel?.sections?.[index - 1];
    const previousWidth = previousSection?.displayWidth ?? 100;
    const currentWidth = section.displayWidth ?? 100;
    const width = section.displayWidth ?? 100;
    const previousName = previousSection?.name ?? "";
    const isInvestmentApproach =
      section.name === AddtionalVisualizeTable.INVESTMENT_APPROACH;
    const widthWithinRange =
      previousWidth + currentWidth >= 90 && previousWidth + currentWidth <= 100;
    const isNotInvestmentApproach =
      previousName !== AddtionalVisualizeTable.INVESTMENT_APPROACH;

    const hasDivider =
      isInvestmentApproach || (widthWithinRange && isNotInvestmentApproach);
    const {
      standardRiskMeasure,
      targetGrowthExposure,
      targetDefensiveExposure,
      activeShare,
      passiveShare,
    } = getInvestmentApproachChartData(section);
    const isShowAddtionalChart =
      standardRiskMeasure &&
      targetGrowthExposure &&
      targetDefensiveExposure &&
      activeShare &&
      passiveShare &&
      isInvestmentApproach;
    const isAssetAllocationNotesSection =
      section.name === AddtionalVisualizeTable.ASSET_ALLOCATION_NOTES;
    const isAlignTableEnd = isAssetAllocationNotesSection;

    switch (section.displayType?.id) {
      case SectionDisplayTypeId.Table: {
        //specific case: display chart for investment approach section, only show when had data
        if (isShowAddtionalChart) {
          const targetDefensivePecentage =
            getPercentageValue(targetGrowthExposure);
          const targetGrowthPecentage = getPercentageValue(
            targetDefensiveExposure,
          );
          const activeSharePecentage = getPercentageValue(activeShare);
          const passiveSharePecentage = getPercentageValue(passiveShare);
          const targetExposureMetaData =
            PercentageChartMetaData["TargetExposure"];
          const activePassiveShareMetaData =
            PercentageChartMetaData["ActivePassiveShare"];

          return (
            <>
              <SHStack width={"100%"} gap={3} flexDirection={"row"}>
                <SHStack width={`${width}%`} paddingBottom="10px">
                  <InvestmentTableItem section={section} width={width} />
                </SHStack>
                <SHStack paddingTop={"10px"} gap={2} flex={1}>
                  <ChartTitle>Standard risk measure</ChartTitle>
                  <GaugeChart
                    highlightedPriority={Number(standardRiskMeasure)}
                  />
                  <ChartTitle>Target exposure</ChartTitle>
                  <SinglePercentageBarChart
                    leftValue={targetGrowthPecentage}
                    rightValue={targetDefensivePecentage}
                    leftBgColor={targetExposureMetaData.leftColor}
                    rightBgColor={targetExposureMetaData.rightColor}
                    rightLabel={targetExposureMetaData.rightLabel}
                    leftLabel={targetExposureMetaData.leftLabel}
                  />
                  <ChartTitle>Active/passive share</ChartTitle>
                  <SinglePercentageBarChart
                    leftValue={activeSharePecentage}
                    rightValue={passiveSharePecentage}
                    leftBgColor={activePassiveShareMetaData.leftColor}
                    rightBgColor={activePassiveShareMetaData.rightColor}
                    rightLabel={activePassiveShareMetaData.rightLabel}
                    leftLabel={activePassiveShareMetaData.leftLabel}
                  />
                </SHStack>
              </SHStack>
              {hasDivider && (
                <SHDivider sx={{ height: "1px", width: "100%" }} />
              )}
            </>
          );
        }
        //specific case: case for display when has pie chart
        if (section.hasChart && section.chartType?.id === ChartTypeId.Pie) {
          return (
            <>
              {renderHasChartSection(section, width)}
              {hasDivider && (
                <SHDivider sx={{ height: "1px", width: "100%" }} />
              )}
            </>
          );
        }
        //normal case - ony table
        return (
          <>
            <SHStack width={`${width}%`} paddingBottom="10px">
              <InvestmentTableItem
                alignEnd={isAlignTableEnd}
                section={section}
                width={width}
              />
            </SHStack>
            {hasDivider && <SHDivider sx={{ height: "1px", width: "100%" }} />}
          </>
        );
      }

      case SectionDisplayTypeId.MainBodyPage: {
        return (
          <SHStack width="100%" mb="20px">
            {section.features
              ?.filter((feature) => feature.name !== "Model")
              .map((feature) => {
                return (
                  <React.Fragment key={feature.id}>
                    {renderFeatureItem(feature)}
                    <SHDivider />
                  </React.Fragment>
                );
              })}
          </SHStack>
        );
      }
    }
  };

  const showUpgradeToView = () => {
    if (
      user?.userType === UserType.AdviserTrial ||
      user?.userType === UserType.AdviserAdminTrial
    ) {
      return true;
    }
    return false;
  };

  const renderPageUpdated = () => {
    if (!selectedModel?.pageUpdated) return <></>;
    const year = selectedModel.pageUpdated
      ? selectedModel.pageUpdated.getFullYear() % 100
      : "";
    const month = selectedModel.pageUpdated
      ? (selectedModel.pageUpdated.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")
      : "";
    const day = selectedModel.pageUpdated
      ? selectedModel.pageUpdated.getDay().toString().padStart(2, "0")
      : "";

    return (
      <SHTypography variant="body1" colorVariant="third" textAlign={"right"}>
        Page updated: {`${day}/${month}/${year}`}
      </SHTypography>
    );
  };

  return (
    <SHStack>
      {showUpgradeToView() ? (
        <UpgradeSubscriptionNotice />
      ) : (
        <ProductFeaturesLayout
          hasData={!isEmpty(investmentProductModelsData)}
          decreaseLeft={true}
          leftSide={
            <InvestmentProductCollapseMenu
              sx={{ width: "219px !important" }}
              investmentProductModelsData={investmentProductModelsData}
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
              expandedIndexProp={0}
            />
          }
          rightSide={
            <SHStack
              direction={"row"}
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <SHStack
                direction={"row"}
                sx={{ justifyContent: "space-between" }}
                width="100%"
              >
                <SectionTitle
                  groupName={
                    !isEmpty(investmentProductModelsData)
                      ? `${
                          investmentProductModelsData?.[selectedIndex[0]].name
                        }  ${selectedModel?.name ?? ""}
                       `
                      : ""
                  }
                  sectionName={selectedModel?.sections?.[0]?.name || ""}
                />
                {renderPageUpdated()}
              </SHStack>
              <SHDivider sx={{ height: "1px", width: "100%" }} />
              {selectedModel?.sections?.map((section, index) => (
                <React.Fragment key={section.id}>
                  {renderSectionItem(section, index)}
                </React.Fragment>
              ))}
            </SHStack>
          }
          isLoading={isModelsDataLoading}
        />
      )}
    </SHStack>
  );
};
