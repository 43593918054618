import { ConfigurationSection, HighlightType } from "@models/configuration";
import { RecordType } from "@models/core";
import { ProductFieldDTO } from "@models/product/entities/product";
import { ProductDataStatus } from "@models/product/enums/productDataStatus";

export interface FeatureListOfValueDTO {
  sections: ConfigurationSection[] | null;
  fieldTypes: RecordType<FieldTypeId>[] | null;
  businessMetricTypes: RecordType<BusinessMetricTypeId>[] | null;
}

export class ConfigurationFeature {
  id?: string = "";
  name: string = "";
  description?: string = "";
  order?: number | null = null;
  fieldType?: RecordType<FieldTypeId> = new RecordType();
  isBusinessMetric?: boolean = false;
  businessMetricType?: RecordType<BusinessMetricTypeId> | null = null;
  isPositiveScore?: boolean = false;
  isArchived?: boolean = false;
  flagType?: string = "";
  highlightType?: HighlightType;
  createdDate?: Date = new Date();
  lastModifiedDated?: Date = new Date();
  publishedUserId?: string = "";
  lastUpdateUserId?: string = "";
  lastUpdateUserName?: string = "";
  configurationFeatureMainId?: string = "";
  dataStatus?: ProductDataStatus;
  configurationVersion?: number;
  productData?: ProductFieldDTO;
  isModified?: null | boolean;
}

export class ConfigurationFeature_Ext extends ConfigurationFeature {
  sectionId?: string = "";
  subSectionId?: string = "";
  isSelected?: boolean = false;
}

export class ConfigurationFeatureCreateDTO {
  name: string = "";
  description?: string = "";
  historyConfigurationSectionId: string = "";
  fieldTypeId: string = "";
  businessMetricId?: string = "";
  isBusinessMetric?: boolean = false;
  isPositiveScore?: boolean = false;
}

export class ConfigurationFeatureUpdateDTO {
  name: string = "";
  description?: string = "";
  fieldTypeId: string = "";
  isBusinessMetric?: boolean = false;
  businessMetricTypeId?: string = "";
  isPositiveScore?: boolean = false;
  historyConfigurationSectionId: string = "";
  configurationFeatureId: string | null = "";
}

export enum FieldTypeId {
  TextShort = "TextShort",
  TextLong = "TextLong",
  Number = "Number",
  NumberMillion = "NumberMillion",
  YesNo = "YesNo",
  Image = "Image",
  Slideshow = "Slideshow",
  DateTime = "DateTime",
  Percentage = "Percentage",
  Chart = "Chart",
  Composite = "Composite",
}

export enum BusinessMetricTypeId {
  FinancialStrength = "FinancialStrength",
  Esg = "Esg",
  Operational = "Operational",
}

export enum FeatureSectionName {
  ProductMenu = "Product menu",
  AccountManagement = "Account management",
  PortfolioManagement = "Portfolio management",
  CashflowManagement = "Cashflow management",
  DecisionSupport = "Decision support",
  Tracking = "Tracking",
  ClientReporting = "Client reporting",
  ClientAccess = "Client access",
  PlanningCompliance = "Planning and compliance",
  WholeView = "Whole of wealth view",
  Integration = "Integration",
}