import { SelectRegions } from "@components/auto-completes/regions";
import {
  AutoCompleteChangeReason,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { UserInformationCard } from "@components/user-information-card";
import { UserInformationCardSkeleton } from "@components/user-information-card/UserInformationCardSkeleton";
import { DELAY_TIME } from "@constants";
import { useNotification } from "@hooks/useNotification";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { ProductBDMUserDTO } from "@models/product/entities/product";
import { RegionDTO } from "@models/public/region";
import { RootState } from "@redux/store";
import { getProductBDMUsers } from "@services/product/productsService";
import { getRegions } from "@services/public/region";
import { isEmpty, isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";

export const FindBDMTab = () => {
  const { productId } = useParams<{ productId: string }>();
  const { notify } = useNotification();
  const {
    productUI: { productName },
  } = useSelector((state: RootState) => state.product);

  const [isLoadingRegion, setIsLoadingRegion] = useState(true);
  const [isLoadingBDMUsers, setIsLoadingBDMUsers] = useState(true);
  const [prevRegionIds, setPrevRegionIds] = useState<string[]>([]);
  const [regionIds, setRegionIds] = useState<string[]>([]);
  const [bdmUserData, setBDMUserData] = useState<ProductBDMUserDTO[]>([]);
  const [regionData, setRegionData] = useState<RegionDTO[]>([]);
  const [selectChangeReason, setSelectChangeReason] = useState<
    string | undefined
  >();

  const showDataNotFound = useMemo(() => {
    return (
      !(isLoadingBDMUsers || isLoadingRegion) &&
      isEmpty(regionData) &&
      isEmpty(bdmUserData)
    );
  }, [isLoadingBDMUsers, isLoadingRegion, bdmUserData, regionData]);

  const loadRegions = async () => {
    setIsLoadingRegion(true);
    const { data, message } = await getRegions();

    setIsLoadingRegion(false);
    setIsLoadingBDMUsers(true);
    if (data) {
      setRegionData(data);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const hasUpdateRegionIds = useMemo(() => {
    return !isEqual(regionIds, prevRegionIds);
  }, [regionIds, prevRegionIds]);

  const loadBDMUsers = async (ids?: string[]) => {
    if (!productId) return;
    setIsLoadingBDMUsers(true);

    const startTime = Date.now();
    const { data, message } = await getProductBDMUsers(productId, ids ?? []);

    //Make animation smoother when API response too fast
    const elapsedTime = Date.now() - startTime;
    if (elapsedTime < DELAY_TIME) {
      await new Promise((resolve) =>
        setTimeout(resolve, DELAY_TIME - elapsedTime),
      );
    }

    setIsLoadingBDMUsers(false);
    if (data) {
      setBDMUserData(data);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setPrevRegionIds(ids ?? []);
  };

  const renderRegionDropdown = () => {
    if (isLoadingRegion)
      return (
        <SHStack width={"30%"}>
          <SHSkeleton height={45} width={"100%"} />
        </SHStack>
      );

    return (
      <SHStack width={"30%"}>
        <SelectRegions
          regionData={regionData}
          value={regionIds}
          onChange={(value, reason) => {
            setRegionIds(value);
            setSelectChangeReason(reason);
          }}
          textFieldProps={{
            placeholder: "Filter by region",
          }}
          onClose={() => {
            if (hasUpdateRegionIds) {
              loadBDMUsers(regionIds);
            }
          }}
        />
      </SHStack>
    );
  };

  const renderBDMUsers = () => {
    if (isLoadingBDMUsers)
      return (
        <SHStack
          gap={"10px"}
          direction={"row"}
          sx={{ flexWrap: "wrap", width: "100%" }}
        >
          <UserInformationCardSkeleton />
          <UserInformationCardSkeleton />
          <UserInformationCardSkeleton />
        </SHStack>
      );

    return !isEmpty(bdmUserData) ? (
      bdmUserData?.map((userInfo, index) => (
        <UserInformationCard
          key={index}
          showUserType={true}
          userInfo={userInfo}
        />
      ))
    ) : (
      <SHStack width={"100%"} alignItems="center" justifyContent={"center"}>
        <SHTypography variant="body4">
          No results match your criteria.
        </SHTypography>
      </SHStack>
    );
  };

  useEffectOnce(() => {
    loadRegions();
    loadBDMUsers();
  });

  useEffect(() => {
    if (isEqual(selectChangeReason, AutoCompleteChangeReason.Clear)) {
      loadBDMUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectChangeReason]);

  return (
    <SHStack>
      <TopBarContainer>
        <TopBar
          title={!isEmpty(productName) ? productName : "Product details"}
        />
      </TopBarContainer>
      {showDataNotFound ? (
        <SHStack
          width={"100%"}
          alignItems="center"
          justifyContent={"center"}
          marginTop={2}
        >
          <SHTypography variant="body4">Data not found!</SHTypography>
        </SHStack>
      ) : (
        <SHStack spacing={2} marginY={2}>
          {renderRegionDropdown()}
          <SHStack
            gap={"10px"}
            sx={{ flexWrap: "wrap", width: "100%" }}
            direction={"row"}
          >
            {renderBDMUsers()}
          </SHStack>
        </SHStack>
      )}
    </SHStack>
  );
};
