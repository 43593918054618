import {
  SHHtmlBlock,
  SHMenuItem,
  SHMenuList,
  SHPopover,
  SHStack,
  SHTableCell,
  SHTypography,
} from "@components/design-systems";
import { ArrowDownSVG, SortAscSVG, SortDescSVG } from "@components/svgs";
import {
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useTheme,
} from "@mui/material";
import {
  subHeaderTooltipTitleMapping,
  availabilityMainHeaderCellBorderIds,
  availabilitySubHeaderCellBorderIds,
  mainHeaderCellBorderIds,
  subHeaderCellBorderIds,
  availabilitySubHeaderTooltipTitleMapping,
  availabilitySubHeaderTooltipContentMapping,
} from "@pages/managed-accounts/sma-list/config";
import { Header, flexRender } from "@tanstack/react-table";
import { hexToRGBA } from "@utils/color";
import React from "react";
import { SubHeader } from "@models/managed-accounts/enums/subHeader";
import { DELAY_TIME } from "@constants";

export const SMAHeadCell = ({
  header,
  isMainHeader,
  isLastedColumn,
  cellIndex,
  isAvailability,
}: {
  header: Header<any, unknown>;
  isMainHeader: boolean;
  isLastedColumn: boolean;
  cellIndex: number;
  isAvailability: boolean;
}) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "sh-head-cell-popover" : undefined;
  const { column } = header;

  const isCanSort = column.getCanSort();
  const isSorted = column.getIsSorted();
  const isCanShowMenu = isCanSort;

  const HeaderTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      "&:before": {
        border: "1px solid #c2c2c2",
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      maxWidth: column.id === SubHeader.UnderlyingPerformanceFees ? 360 : 285,
    },
  }));
  const removePaddingForModelHeader = isMainHeader
    ? header.id.includes("Model_Model")
    : header.id.includes("Model");
  const handleShowBorder = () => {
    const cellId = isMainHeader ? header.id.split("_")[1] : header.id;

    if (isAvailability) {
      return isMainHeader
        ? availabilityMainHeaderCellBorderIds.find(
            (cell) => cell === cellId,
          ) !== undefined
        : availabilitySubHeaderCellBorderIds.find((cell) => cell === cellId) !==
            undefined;
    }

    return isMainHeader
      ? mainHeaderCellBorderIds.find((cell) => cell === cellId) !== undefined
      : subHeaderCellBorderIds.find((cell) => cell === cellId) !== undefined;
  };

  return (
    <>
      <SHTableCell
        className="SMAListTableHeadCell"
        id={header.id}
        aria-describedby={id}
        colSpan={header.colSpan}
        onClick={(event: any) => {
          if (!isCanShowMenu) return;
          setAnchorEl(event.currentTarget);
        }}
        sx={{
          display: "flex",
          p: removePaddingForModelHeader
            ? "0px !important"
            : "0px 10px !important",
          height: isMainHeader ? 25 : 42,
          borderBottomWidth: isMainHeader ? "0px !important" : undefined,
          borderRight: handleShowBorder()
            ? `1px solid ${palette.divider}`
            : undefined,
          width: header.getSize(),
          ...(isCanShowMenu
            ? {
                cursor: "pointer",
              }
            : {}),
        }}
      >
        <SHStack spacing={1} direction="row" alignItems={"center"}>
          <HeaderTooltip
            title={
              <SHStack spacing={1}>
                <SHTypography
                  variant="body2"
                  fontSize={14}
                  fontWeight={700}
                  lineHeight={1.2}
                >
                  {availabilitySubHeaderTooltipTitleMapping[column.id]}
                </SHTypography>

                <SHHtmlBlock
                  variant="body3"
                  zIndex={1}
                  color={palette.text.disabled}
                  content={
                    availabilitySubHeaderTooltipContentMapping[column.id]
                  }
                  textAlign={"left"}
                />
              </SHStack>
            }
            enterDelay={DELAY_TIME}
            enterNextDelay={DELAY_TIME}
            disableHoverListener={
              subHeaderTooltipTitleMapping[column.id] == null
            }
          >
            <span>
              <SHTypography
                variant="body1"
                colorVariant="third"
                lineHeight={1.2}
                fontSize={isMainHeader && cellIndex === 0 ? 10 : 12}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(column.columnDef.header, header.getContext())}
              </SHTypography>
            </span>
          </HeaderTooltip>
          {isCanSort && (
            <IconButton
              sx={{ padding: "4px" }}
              onClick={(event: any) => {
                event.stopPropagation();
                if (isSorted === "desc") {
                  column.clearSorting();
                  return;
                }
                column.toggleSorting(!isSorted ? false : true);
              }}
            >
              <SHStack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  transition: "all ease-in-out 0.2s",
                  transform: `rotate(${isSorted !== "desc" ? 0 : 180}deg)`,
                }}
              >
                <ArrowDownSVG
                  color={isSorted ? palette.primary.main : undefined}
                  width={"12px"}
                  height={"12px"}
                  transform="rotate(180)"
                />
              </SHStack>
            </IconButton>
          )}
        </SHStack>
      </SHTableCell>
      <SHPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: isLastedColumn ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isLastedColumn ? "right" : "left",
        }}
        PaperProps={{
          sx: {
            boxSizing: "border-box",
            boxShadow: "none",
            borderWidth: 1,
            borderColor: palette.divider,
            borderStyle: "solid",
            marginLeft: "-1px",
            marginTop: "-1px",
            borderRadius: 0,
            backgroundColor: hexToRGBA(palette.common.white, 0.95),
            width: "246px",
            filter: "drop-shadow(1px 1px 2px rgba(65, 73, 89, 0.25))",
          },
        }}
      >
        {isCanSort && (
          <SHMenuList sx={{ padding: 0 }}>
            <SHMenuItem
              sx={{ height: "38px" }}
              selected={isSorted === "asc"}
              onClick={(event) => {
                column.clearSorting();
                column.toggleSorting(false);
              }}
            >
              <SHStack spacing={1} direction="row" alignItems="center">
                <SortAscSVG />
                <SHTypography variant="body1">Sort ascending</SHTypography>
              </SHStack>
            </SHMenuItem>
            <SHMenuItem
              sx={{ height: "38px" }}
              selected={isSorted === "desc"}
              onClick={(event) => {
                column.clearSorting();
                column.toggleSorting(true);
              }}
            >
              <SHStack spacing={1} direction="row" alignItems="center">
                <SortDescSVG />
                <SHTypography variant="body1">Sort descending</SHTypography>
              </SHStack>
            </SHMenuItem>
          </SHMenuList>
        )}
      </SHPopover>
    </>
  );
};
