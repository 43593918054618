import { InformationButton } from "@components/buttons/information";
import {
  SHHtmlBlock,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTableHead,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { SHBooleanIcon } from "@components/design-systems/sh-boolean-icon";
import { FieldTypeId } from "@models/configuration";
import {
  InvestmentProductConfigurationModelSection,
  InvestmentProductConfigurationModelsFeature,
  ProductModelValueDTO,
} from "@models/product/investment-product/entities/model";
import { useTheme } from "@mui/material";
import { FRCell } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/table-cell";
import { ReadMoreButton } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/read-more";
import {
  defaultCellWidth,
  defaultFirstCellWidth,
  defaultNumOfCell,
  defaultNumOfHeadCol,
  defaultTextLength,
  headerPadding,
} from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/configs";
import { getPercentageValue, tryParseJson } from "@pages/suppliers/_id/util";
import {
  AssetAllocationNotesTable,
  isSubColumn,
} from "@pages/suppliers/constant";
import { isEmpty } from "lodash";
interface InvestmentTableItemProps {
  section: InvestmentProductConfigurationModelSection;
  width: number;
  alignEnd?: boolean;
}
export const InvestmentTableItem = ({
  section,
  width,
  alignEnd = false,
}: InvestmentTableItemProps) => {
  const { palette } = useTheme();
  const isAssetAllocationNotesTable =
    section.name === AssetAllocationNotesTable;
  let {
    tableHeaderCategory,
    features: sectionFeatures,
    name: sectionName,
  } = section;
  if (isEmpty(sectionFeatures)) return <></>;

  let cellWidth = defaultCellWidth;
  let firstCellWidth = defaultFirstCellWidth;
  let numOfCell = defaultNumOfCell;
  let numberOfHeadCol = defaultNumOfHeadCol;

  if (tableHeaderCategory?.values) {
    numOfCell = tableHeaderCategory?.values.length;
    cellWidth = (width - headerPadding) / (numOfCell + 1);
    firstCellWidth = width / (numOfCell + 1) + headerPadding;
  }
  // Specific cell width for Asset allocation notes section
  if (isAssetAllocationNotesTable) {
    cellWidth = cellWidth + 40;
    sectionFeatures = sectionFeatures?.filter(
      (feature) => !isEmpty(feature.productData?.value),
    );
  }
  const getMaxTextLength = (
    isTable: boolean,
    features?: InvestmentProductConfigurationModelsFeature[] | null,
  ) => {
    if (!isTable || !features) return defaultTextLength;

    switch (features.length) {
      case 1:
        return 60;
      case 2:
        return 20;
      default:
        return defaultTextLength;
    }
  };

  if (isEmpty(tableHeaderCategory?.values)) {
    numberOfHeadCol += 1;
  }
  const getFeatureValue = (
    feature: InvestmentProductConfigurationModelsFeature,
    productData: ProductModelValueDTO,
  ) => {
    const value = productData?.value;

    let fieldType = feature.fieldType?.id;
    if (feature.fieldType?.id === FieldTypeId.Composite) {
      const header = tableHeaderCategory?.values.find((value) => {
        return value.id === productData.headerId;
      });
      fieldType = header?.fieldType as FieldTypeId;
    }

    switch (fieldType) {
      case FieldTypeId.YesNo: {
        switch (value?.toString()) {
          case "true":
            return <SHBooleanIcon isTrue />;
          case "false":
            return <SHBooleanIcon isTrue={false} />;
        }

        break;
      }

      case FieldTypeId.Percentage: {
        if (!value) {
          return (
            <SHTypography variant="body3" colorVariant="third">
              No data
            </SHTypography>
          );
        }

        const percentageValue = getPercentageValue(value);
        return (
          <SHHtmlBlock
            variant="body3"
            color={palette.text.third}
            content={`${percentageValue}%`}
          />
        );
      }

      default: {
        if (!value) {
          return (
            <SHTypography variant="body3" colorVariant="third">
              No data
            </SHTypography>
          );
        }
        const maxTextLength = getMaxTextLength(
          isAssetAllocationNotesTable,
          sectionFeatures,
        );
        const words = value.toString().split(" ");
        let hasMoreWords = words.length > maxTextLength;

        const displayContent = hasMoreWords
          ? words.slice(0, maxTextLength).join(" ")
          : value;

        return (
          <>
            {displayContent}
            {hasMoreWords && (
              <>
                &nbsp;
                <ReadMoreButton content={value} />
              </>
            )}
          </>
        );
      }
    }
  };
  return (
    <SHTableContainer
      sx={{
        paddingTop: "12px",
        overflowY: "hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: alignEnd ? "flex-end" : "flex-start",
        alignItems: alignEnd ? "flex-end" : "flex-start",
      }}
    >
      <SHTable>
        {/* Header */}
        <SHTableHead>
          <SHTableRow
            sx={{
              th: {
                height: "50.26px",
                lineHeight: "1rem !important",
                paddingY: "0px !important",
              },
            }}
          >
            <FRCell
              sx={{
                width: `${cellWidth}% !important`,
              }}
              colSpan={numberOfHeadCol}
            >
              <SHTypography
                lineHeight="1rem !important"
                variant="body4"
                fontWeight={600}
              >
                {sectionName}
              </SHTypography>
            </FRCell>
            {tableHeaderCategory?.values.map((product) => {
              return (
                <FRCell
                  key={product.id}
                  sx={{
                    width: `${cellWidth}% !important`,
                  }}
                >
                  <SHStack direction="row" flexWrap={"nowrap"}>
                    <SHTypography
                      variant="body4"
                      fontWeight={600}
                      lineHeight="1rem !important"
                    >
                      {product.name}
                    </SHTypography>
                    {!isEmpty(product.description) && (
                      <InformationButton content={product.description} />
                    )}
                  </SHStack>
                </FRCell>
              );
            })}
          </SHTableRow>
        </SHTableHead>
        {/* Body */}
        <SHTableBody
          sx={{
            "&.MuiTableBody-root::before": {
              lineHeight: "0px",
              fontSize: "0px",
            },
          }}
        >
          {/* Empty row for separation between header and body */}
          <SHTableRow
            style={{
              height: "13px",
              border: 0,
              borderBottomWidth: 1,
              background: "transparent",
            }}
          ></SHTableRow>
          {/* Features */}
          {sectionFeatures?.map((feature, index) => {
            let productDataValues: ProductModelValueDTO[] | string =
              tryParseJson(feature.productData?.value as string);

            if (typeof productDataValues !== "object") {
              productDataValues = [{ headerId: "", value: productDataValues }];
            }

            return (
              <SHTableRow
                key={feature.id}
                sx={{
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderTopWidth: index !== 0 ? 0 : 1,
                  backgroundColor:
                    index % 2 === 0
                      ? `${palette.secondary[50]} !important`
                      : "transparent !important",
                  td: { overflow: "hidden" },
                }}
              >
                <FRCell
                  sx={{
                    width: `${firstCellWidth}% !important`,
                    lineHeight: "1rem !important",
                    height: 5,
                    // subcolumn have more indent from the left
                    paddingLeft: isSubColumn(feature.name) ? 3.5 : undefined,
                  }}
                >
                  <SHStack direction="row">
                    <SHTypography
                      display="block"
                      variant="body4"
                      lineHeight="1rem !important"
                      // subcolumn not bold
                      fontWeight={isSubColumn(feature.name) ? undefined : 600}
                    >
                      {feature.name}
                    </SHTypography>
                    {!isEmpty(feature.description) && (
                      <InformationButton content={feature.description} />
                    )}
                  </SHStack>
                </FRCell>
                {productDataValues?.map((value) => {
                  const numberOfOtherCell =
                    numOfCell - ((productDataValues.length ?? 1) - 1);
                  return (
                    <FRCell
                      key={value.headerId}
                      sx={{
                        width: `${cellWidth}% !important`,
                        lineHeight: "1rem !important",
                        paddingX: isAssetAllocationNotesTable
                          ? "0px"
                          : undefined,
                      }}
                      colSpan={numberOfOtherCell}
                    >
                      <SHTypography
                        variant="body4"
                        lineHeight="1rem !important"
                      >
                        {getFeatureValue(feature, value)}
                      </SHTypography>
                    </FRCell>
                  );
                })}
              </SHTableRow>
            );
          })}
        </SHTableBody>
      </SHTable>
    </SHTableContainer>
  );
};
