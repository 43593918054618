import { SHBox, SHBoxProps, SHTypography } from "@components/design-systems";

interface SinglePercentageBarChartProps extends SHBoxProps {
  leftValue?: number | string;
  rightValue?: number | string;
  leftBgColor?: string;
  rightBgColor?: string;
  leftLabel?: string;
  rightLabel?: string;
}
export const SinglePercentageBarChart = ({
  leftValue,
  rightValue,
  leftBgColor = "#07A274",
  rightBgColor = "#0EC48F",
  leftLabel,
  rightLabel,
  ...props
}: SinglePercentageBarChartProps) => {
  return (
    <SHBox {...props} display="flex" gap={0.3} overflow={"hidden"}>
      <SHBox
        display={"flex"}
        gap={0}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        flex={`${leftValue}`}
        bgcolor={leftBgColor}
      >
        <SHTypography variant="caption" color="white">
          {leftValue}%
        </SHTypography>
        <SHTypography lineHeight={1} variant="caption" color="white">
          {leftLabel}
        </SHTypography>
      </SHBox>
      <SHBox
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"center"}
        flex={`${rightValue}`}
        bgcolor={rightBgColor}
      >
        <SHTypography fontWeight={600} variant="caption">
          {rightValue}%
        </SHTypography>
        <SHTypography lineHeight={1} fontWeight={600} variant="caption">
          {rightLabel}
        </SHTypography>
      </SHBox>
    </SHBox>
  );
};
